import React from 'react'

const MenuSmall = () => {
    return (
        <div>
            small menu
        </div>
    )
}

export default MenuSmall
